import { useRef, useEffect, memo } from "react"

const HOST_NAME = "https://noodle.saguto.golf"
// const HOST_NAME = "https://noodle.shop"
// const HOST_NAME = "https://d14c-75-31-74-5.ngrok.io"

function UserProfile() {
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current) {
            const target = document.createElement("div")
            target.className = "noodle-widget"
            target.setAttribute(
                "data-product-url",
                `${HOST_NAME}/sagutogolf/user-profile`
            )
            ref.current.appendChild(target)
            const script = document.createElement("script")
            script.src = `${HOST_NAME}/widget.js`
            ref.current.appendChild(script)
        }

        // remove popover widget
        const launcher = document.getElementById("noodle-popover-launcher")
        if (launcher) {
            launcher.style.display = "none"
        }

        const main = document.getElementById("noodle-popover-main")
        if (main) {
            main.style.display = "none"
        }

        setTimeout(() => {
            main.style.display = "none"
            launcher.style.display = "none"
        }, 2000)

        return () => {
            if (launcher && main) {
                launcher.style.display = "initial"
                main.style.display = "none"
            }
        }
    }, [])

    return <div ref={ref}></div>
}

export default UserProfile
